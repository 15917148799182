import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { getApeLootBoxPrice } from "@/helpers/get-ape-lootbox-price";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";

const messageToSign = `I'm opening lootboxes`;

export const openLootBoxOffChain = async ({ apeId, type, amount = 1, useMeth = false }) => {
  const wallet = useWeb3Store().walletAddress;
  const ape = useWalletCollectionStore().allApes.find((ape) => ape.id == apeId);
  let signature;
  if (true) {
    try {
      signature = await web3.eth.personal.sign(messageToSign, wallet);
    } catch (err) {
      useToast().error({ title: "Couldn't sign the message" });
      console.error(err);
    }
  }
  if (!signature) return false;
  const data = await useApesStorageApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/lootBox/offchain/open",
    method: "POST",
    data: {
      methPayment: useMeth,
      type,
      apeId,
      wallet,
      amount,
      network: Config.network.name,
      signature,
    },
  });

  const { winners, traits, isNewTraits, methAmounts, flagsAmounts } = data;
  console.log(data);

  const images = traits;
  const winningIds = winners;
  let traitStars;
  if (type === "common") {
    traitStars = await useApesStorageApi({
      toast: { error: true },
    }).exec({
      url: "/metadata/traits-stars",
      params: {
        type: "common",
      },
    });
  }
  const lootBoxItems = images.map((item) => {
    const image =
      item.rarityUnisexPreviewImageSrc ||
      item.rarityMalePreviewImageSrc ||
      item.rarityFemalePreviewImageSrc;

    return {
      ...item,
      image: resizeImageCF(image, "512"),
      stars: type === "common" ? traitStars[item.id]?.stars : undefined,
    };
  });

  let boosters = [];
  if (type === "common") {
    let currentBooster = ape?.lootBox?.booster || 1;
    winningIds.forEach((_) => {
      boosters.push(Math.min(currentBooster, ape?.lootBox?.maxBoost));
      currentBooster++;
    }, []);
  }

  useModal().close();
  useModal().open({
    name: ModalName.LootBoxOpenModal,
    props: {
      type,
      winningIds,
      isNewTraits,
      flagsAmounts,
      items: lootBoxItems,
      apeId: apeId,
      boosters,
      methAmounts
    },
  });
  return true;
};
