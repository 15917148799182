<template>
    <AModalContent class="lootbox-open-modal" @click.prevent="onModalClick">
        <div
            v-if="state > 0 && !isLoading"
            key="title"
            class="lootbox-open-modal-title"
        >{{ spinCount }}/{{ totalSpins }} boxes opened</div>

        <Transition name="t-lootbox-open-start">
            <LootBoxOpenVideo
                v-if="state === 0"
                :type="type"
                @end="onVideoEnd"
                @contextmenu.prevent
            />
        </Transition>
        <div class="lootbox-open-modal-content">
            <Transition name="t-lootbox-open">
                <div v-if="state === 1 && isLoading" class="lootbox-open-modal-loading">
                    <img src="@/assets/img/spinner-clear.svg" class="spinner" width="80" />
                </div>
                <LootBoxOpenRoulette
                    v-else-if="state === 1 && !isLoading"
                    :available-items="availableItems"
                    :winning-item="winningItem"
                    :is-skip="skipAnimation"
                    :booster="lootboxBooster"
                    :type="type"
                    @end="onAnimationEnd"
                    @contextmenu.prevent
                />
            </Transition>
            <Transition name="t-lootbox-open-prize">
                <LootBoxOpenPrize
                    v-if="state === 2"
                    :winning-item="prizeItem"
                    :can-open-again="type === 'common' && spinCount >= totalSpins && apeId > 0"
                    :open-again-price="lootboxPrice"
                    :can-open-timer-ends="canOpenTimerEnds"
                    :booster="lootboxBooster"
                    :max-boost="lootboxMaxBoost"
                    :type="type"
                    :is-new="isPrizeNew"
                    :flags-amount="flagsAmount"
                    :win-meth-amount="winMethAmount"
                    :is-next-close="spinCount >= totalSpins"
                    @reopen="onReopen"
                    @next="goNext"
                />
            </Transition>
        </div>
    </AModalContent>
</template>
<script>
import utilitiesMetadata from "@/config/utilities-metadata"
import { computed, defineComponent, nextTick, onMounted, ref } from "vue"
import LootBoxOpenRoulette from "../LootBox/LootBoxOpen/LootBoxOpenRoulette.vue"
import AModalContent from "../Modal/AModalContent.vue"
import LootBoxOpenPrize from "../LootBox/LootBoxOpen/LootBoxOpenPrize.vue"
import LootBoxOpenVideo from "../LootBox/LootBoxOpen/LootBoxOpenVideo.vue"
import { openCommonLootBox } from "@/functions/lootbox/openCommonLootBox"
import { useApesStorageApi } from "@/api/useApesStorageApi"
import { useWalletCollectionStore } from "@/store/wallet-collection"
import winSound from "@/assets/audio/lootbox_win.mp3";
import commonWinSound from "@/assets/audio/lootbox_win_common.mp3";
import { openCommonLootBoxOffChain } from "@/functions/lootbox/openCommonLootBoxOffChain"
import { getApeLootBoxPrice } from "@/helpers/get-ape-lootbox-price"
import moment from "moment"
export default defineComponent({
    components: { LootBoxOpenRoulette, LootBoxOpenVideo, AModalContent, LootBoxOpenPrize },
    props: {
        winningIds: {
            type: Array,
            default: () => []
        },
        methAmounts: {
            type: Array,
            default: () => []
        },
        flagsAmounts: {
            type: Array,
            default: () => []
        },
        isNewTraits: {
            type: Array,
            default: () => []
        },
        type: String,
        // [{image: string, id: number}]
        items: {
            type: Array,
            default: () => []
        },
        apeId: Number,
        boosters: {
            type: Array,
            default: () => []
        },
    },
    setup(props, { emit }) {
        emit('init', { closeOnOverlay: false })
        const state = ref(0)
        const spinCount = ref(0)
        const skipAnimation = ref(false)
        const isLoading = ref(false)
        const totalSpins = computed(() => props.winningIds.length)
        const itemsForSpin = computed(() => {
            return props.items.map(item => {
                if (props.type === 'common' && (item.rarity === 'legendary' || item.rarity === 'epic')) {
                    return {
                        ...item,
                        image: item.rarity === 'legendary' ? require('@/assets/img/lootbox/lootbox-legendary.jpg') : require('@/assets/img/lootbox/lootbox-epic.jpg')
                    }
                }
                return item
            })
        })
        const availableItems = computed(() => {
            let nextItems = itemsForSpin.value.slice(spinCount.value * 20, (spinCount.value + 1) * 20)
            if (nextItems.length < 3) {
                nextItems = itemsForSpin.value.slice(0, 20)
            }
            return nextItems
        })
        const winningItem = ref()
        const prizeItem = ref()
        const isPrizeNew = ref(false)
        const flagsAmount = ref(0)
        const winMethAmount = ref()
        const lootboxBooster = ref(1)
        const run = () => {
            skipAnimation.value = false
            winningItem.value = itemsForSpin.value.find(itm => itm.id == props.winningIds[spinCount.value])
            prizeItem.value = props.items.find(itm => itm.id == props.winningIds[spinCount.value])
            isPrizeNew.value = props.isNewTraits[spinCount.value]
            flagsAmount.value = props.flagsAmounts[spinCount.value]
            winMethAmount.value = props.methAmounts[spinCount.value]
            lootboxBooster.value = props.boosters[spinCount.value]
            state.value = 1
            preloadAssets()
        }
        const currentApe = computed(() => {
            const ape = useWalletCollectionStore().allApes.find(ape => ape.id === props.apeId)
            return ape
        })
        const onAnimationEnd = () => {
            spinCount.value++
            state.value = 2
            if (spinCount.value >= totalSpins.value) {
                if (currentApe.value) {
                    currentApe.value.lootBox.openUntil = moment().add(2, "minutes").toDate();
                }
                return
            }
        }
        const onModalClick = () => {
            if (state.value === 1) {
                skipAnimation.value = true
            }
        }
        const goNext = () => {
            if (spinCount.value >= totalSpins.value) {
                emit('close')
                return
            }
            run()

        }
        const onVideoEnd = () => {
            run()
        }
        const preloadAssets = async () => {
            isLoading.value = true
            try {
                const resolvers = availableItems.value.map(item => {
                    return new Promise(resolve => {
                        const img = new Image()
                        img.onload = () => {
                            resolve()
                        }
                        img.onerror = () => {
                            resolve()
                        }
                        img.src = item.image
                    })
                })
                // hoping audio will be preloaded
                const audio = new Audio()
                audio.src = props.type === 'common' ? commonWinSound : winSound;
                audio.preload = "auto";
                await Promise.all(resolvers)
            } catch (err) {
                console.error(err)
            }
            isLoading.value = false
        }
        // preloadAssets()

        const canOpenTimerEnds = computed(() => {
            return currentApe.value?.lootBox?.openUntil
        })

        const lootboxPrice = computed(() => {
            if (!currentApe.value) return
            return getApeLootBoxPrice(currentApe.value)
        })
        const lootboxMaxBoost = computed(() => {
            return currentApe.value?.lootBox?.maxBoost
        })

        const onReopen = async () => {
            await openCommonLootBoxOffChain({ apeId: props.apeId, amount: 1 });
        }
        return { goNext, lootboxMaxBoost, isPrizeNew, flagsAmount, winMethAmount, lootboxBooster, isLoading, availableItems, canOpenTimerEnds, prizeItem, onReopen, lootboxPrice, skipAnimation, onModalClick, onVideoEnd, totalSpins, spinCount, state, winningItem, onAnimationEnd }
    }
})
</script>
<style lang="scss">
.lootbox-open-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--ape-constructor-bg);
    transform: none;
    &-content {
        min-height: 310px;
        max-width: 100%;
        width: 100%;
    }
    &-loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-title {
        margin-bottom: 15px;
        text-align: center;
        font-weight: bold;
        font-size: 28px;
        color: var(--primary);
    }
    @media screen and (max-width: 576px) {
        &-title {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-height: 700px) {
        overflow: auto;
    }
}
.t-lootbox-open-prize-leave-active {
    transition: 0.3s;
}
.t-lootbox-open-prize-enter-active {
    transition: 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}
.t-lootbox-open-prize-leave-to {
    opacity: 0;
}
.t-lootbox-open-prize-enter-from {
    opacity: 0;
    transform: scale(0.1);
}
.t-lootbox-open-leave-active,
.t-lootbox-open-enter-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}
.t-lootbox-open-leave-from,
.t-lootbox-open-enter-from {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
}

.t-lootbox-open-enter-from,
.t-lootbox-open-leave-to {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.1);
}
.t-lootbox-open-start-enter-from,
.t-lootbox-open-start-leave-to {
    opacity: 0;
}
</style>