<template>
  <div class="lootbox-open-video">
    <video
      ref="videoEl"
      class="lootbox-open-video-video"
    >
      <source :src="videoSrc">
    </video>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from "vue"
export default defineComponent({
    props: {
        type: String
    },
    setup(props, { emit }) {
        const videoEl = ref(null)
        const play = async () => {
            try {
                await videoEl.value.play()
                setTimeout(() => {
                    emit('end')
                }, 3500)
            } catch (err) {
                console.log(err)
                emit('end')
            }

        }
        const reset = () => {
            videoEl.value.load();
        }
        const videoSrc = computed(() => {
            if (props.type === 'epic') {
                return require('@/assets/video/lootbox/epic.mp4')
            }
            if (props.type === 'legendary') {
                return require('@/assets/video/lootbox/legendary.mp4')
            }
            return require('@/assets/video/lootbox/common.mp4')
        })
        onMounted(() => {
            play()
        })
        return { play, videoSrc, videoEl, reset }
    }
})
</script>
<style lang="scss">
.lootbox-open-video {
    background-color: var(--ape-constructor-bg);
    display: flex;
    justify-content: center;
    transition: 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    video {
        max-width: 100%;
        height: auto;
    }
}
</style>