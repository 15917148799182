import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { getApeLootBoxPrice } from "@/helpers/get-ape-lootbox-price";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";
import { openLootBoxOffChain } from "./openLootBoxOffchain";
const updateApeLootBoxOpen = async (apeId, amount) => {
  const walletCollectionStore = useWalletCollectionStore();

  const ape = walletCollectionStore.allApes.find((ape) => ape.id === apeId);
  if (!ape) return;
  let newApe = { ...ape };
  if (newApe.freeSpinsCount > 0 && newApe.freeSpinsCount >= amount) {
    newApe.freeSpinsCount -= amount;
  } else if (newApe.freeSpinsCount > 0 && newApe.freeSpinsCount < amount) {
    newApe.freeSpinsCount = 0;
    newApe.power = 0;
  } else if (newApe.power >= 100) {
    newApe.power = 0;
  }
  await walletCollectionStore.fetchApes();
  const newCollectionApeIdx = walletCollectionStore.allApes.findIndex(
    (ape) => ape.id === apeId
  );
  if (newCollectionApeIdx < 0) return;
  // ape with updated lootBox field
  const newCollectionApe = walletCollectionStore.allApes[newCollectionApeIdx];
  console.log("api ape", newCollectionApe);
  newApe = {
    ...newCollectionApe,
    power: newApe.power,
    freeSpinsCount: newApe.freeSpinsCount,
  };
  console.log("updated ape", newApe);

  let newApes = [...walletCollectionStore.allApes];
  newApes.splice(newCollectionApeIdx, 1, newApe);
  walletCollectionStore.allApes = newApes;
};

// apeId can be 0 for wallet based free spins
export const openCommonLootBoxOffChain = async ({ apeId, amount = 1 }) => {
  try {
    const ape = useWalletCollectionStore().allApes.find((ape) => ape.id === apeId);
    if (apeId && !ape) return;
    // if (useWeb3Store().meth < getApeLootBoxPrice(ape, amount)) {
    //   // useModal().open({ name: ModalName.BuyMethModal });
    //   useToast().error({
    //     title: "Cannot open a LootBox",
    //     text: "You don't have enough Methane in Meth Bank",
    //   });
    //   return;
    // }

    const result = await openLootBoxOffChain({ apeId, type: "common", amount });
    if (!result) return;
    if (apeId) {
      updateApeLootBoxOpen(apeId, amount);
    }
    useMethBankStore().fetchMethBank();
  } catch (err) {
    captureError(err);
  }
};
