<template>
  <div class="lootbox-open-roulette-wrapper">
    <div
      class="lootbox-open-roulette"
      :class="{ run: isRunning }"
    >
      <div
        ref="rowWrapper"
        class="lootbox-open-row-wrapper"
      >
        <span class="lootbox-open-ticker" />
        <LootBoxOpenRow
          :items="rowItems"
          :style="rowStyle"
          :highlight-win="isEnd"
          @transitionend.once="onAnimationEnd"
        />
      </div>
      <LootBoxOpenOdd
        v-if="type === 'common'"
        :value="booster"
      />
    </div>
  </div>
</template>
<script>
import sound from "@/assets/audio/spin.wav";

import LootBoxOpenRow from "./LootBoxOpenRow.vue";
import { deviceType } from "@/helpers/utils"
import LootBoxOpenOdd from "./LootBoxOpenOdd.vue";
export default {
  components: { LootBoxOpenRow, LootBoxOpenOdd, },
  props: {
    winningItem: Object,
    availableItems: Array,
    isSkip: Boolean,
    booster: Number,
    type: String,
  },
  data() {
    return {
      isRunning: false,
      isEnd: false,
      spinningAudio: new Audio(sound),
      rowItems: [],
    };
  },
  computed: {
    rowStyle() {
      if (!this.isRunning) return;
      const translate = -10530 + this.randomNum(-120, 120);
      return { transform: `translateX(${translate}px)`, transitionDuration: this.isSkip ? '0.5s' : '' };
    },
  },
  mounted() {
    this.mixItems();
    setTimeout(() => {
      this.run();
    }, 1000);
  },
  methods: {
    initObservable() {
      const elements = [...document.querySelectorAll(".lootbox-open-card")];
      const wrapper = this.$refs.rowWrapper;
      const wrapperRect = wrapper.getBoundingClientRect();
      const wrapperOffsetLeft = wrapperRect.left;
      const width = wrapper.offsetWidth;
      const centerCoords = width / 2;
      let prevCenterIdx = 0;
      let centerIdx = 0;
      const observer = () => {
        if (!this.isRunning || this.isEnd) return;
        prevCenterIdx = centerIdx;
        centerIdx = elements.findIndex((el) => {
          const rect = el.getBoundingClientRect();
          const offsetLeft = rect.left - wrapperOffsetLeft;
          if (offsetLeft >= centerCoords) return true;
          return false;
        });
        if (prevCenterIdx !== centerIdx) {
          this.playSound();
        }
        requestAnimationFrame(observer);
      };
      if (deviceType() !== 'mobile') {
        requestAnimationFrame(observer);
      }
    },
    async playSound() {
      const audio = new Audio(sound);
      try {
        await audio.play();
      } catch (err) {
        console.log('error in playing audio', err)
      }
    },
    onAnimationEnd() {
      this.isEnd = true;
      setTimeout(() => {
        this.$emit("end");
      }, 1000);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    run() {
      this.isRunning = true;
      this.initObservable();
    },

    mixItems() {
      const row = [];
      const total = 100;
      for (let i = 0; i < total; i++) {
        let randomIdx;
        let currentItem;
        let prevItem;
        while (currentItem === prevItem || (i == 89 && currentItem === this.winningItem)) {
          randomIdx = Math.floor(Math.random() * this.availableItems.length)
          currentItem = this.availableItems[randomIdx]
          prevItem = row[i - 1]
        }
        if (i === 90) {
          row[i] = this.winningItem
        } else {
          row[i] = currentItem
        }
      }
      //   Wining item

      this.rowItems = row;
    },
  }
};
</script>

<style lang="scss">
.lootbox-open {
  &-roulette {
    position: relative;
    transition: 0.5s;
    &-wrapper {
      min-width: 0;
      max-width: 960px;
      margin: 0 auto;
    }
    /* &::before {
      content: "";
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // border-radius: 50%;
      -webkit-mask: radial-gradient(
        circle,
        #0000 200px,
        rgba(0, 0, 0, 0.9) 200px
      );
      backdrop-filter: blur(10px);
    } */
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: 30s linear; 
    transition: 8s cubic-bezier(0.12, 0.86, 0.8, 1);

    &-wrapper {
      overflow: hidden;
      width: 100%;
      position: relative;
      z-index: 10;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 50px;
        top: 0;
        bottom: 0;
        z-index: 20;
      }
      &::before {
        left: 0;
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0) 10%,
          rgba(0, 0, 0, 1) 100%
        );
      }
      &::after {
        right: 0;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 10%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
  }
  &-ticker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--primary);
    height: 100%;
    z-index: 20;
  }
  &-card {
    width: 250px;
    height: 250px;
    margin: 0 5px;
    flex-shrink: 0;
    border-bottom: 3px solid currentColor;
    position: relative;
    transition: 0.2s;
    font-size: 0;
    img {
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &-stars {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      svg {
        color: rgba(255, 255, 255, 0.5);
        margin-right: 5px;
        height: auto;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: var(--yellow);
          fill: var(--yellow);
        }
      }
    }
    &.utility {
      color: var(--rarity-common);
    }
    &.common {
      color: var(--rarity-common);
    }
    &.epic {
      color: var(--rarity-epic);
    }
    &.legendary {
      color: var(--rarity-legendary);
    }
    &.highlight {
      border: 4px solid currentColor;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 960px) {
    &-roulette {
      &-wrapper {
        max-width: 100%;
      }
    }
  }
}
</style>