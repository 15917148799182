<template>
  <div class="lootbox-open-card-progress-wrapper">
    <div class="lootbox-open-card-progress">
      <div
        class="lootbox-open-card-progress-active"
        :style="{ '--progress': 100 - progressWidth + '%' }"
      />
      <div class="lootbox-open-card-progress-markers">
        <span
          v-for="item in 5"
          :key="item"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue"
export default defineComponent({
  props: {
    progress: Number
  },
  setup(props) {
    const progressWidth = computed(() => {
      return 100 / 5 * (props.progress || 0)
    })

    return { progressWidth }
  }
})
</script>
<style lang="scss">
.lootbox-open-card-progress {
  height: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 70%;
  position: relative;
  max-width: 120px;

  &-wrapper {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  &-markers {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    span {
      flex: 1;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      &:last-child {
        border: none;
      }
    }
  }
  &-active {
    // position: relative;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    clip-path: inset(0px var(--progress) 0 0);
    margin-top: -1px;
    margin-left: -1px;
    background-color: var(--yellow);
    border-radius: 10px;
    // border-bottom-left-radius: 10px;
    z-index: 10;
  }
}
</style>