<template>
  <div class="lootbox-open-row">
    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="lootbox-open-card"
      :class="{
        'highlight': idx === 90 && highlightWin,
        [item.rarity]: true
      }"
    >
      <img
        :src="item.image"
        alt="prize"
      >
      <!-- <div v-if="item.stars >= 0" class="lootbox-open-card-stars">
        <IStar v-for="star in 5" :key="star" :class="{ active: star <= item.stars }" width="14" />
      </div>-->
      <LootboxOpenCardProgress
        v-if="item.stars >= 0"
        :progress="item.stars"
      />
    </div>
  </div>
</template>
<script>
import IStar from "@/assets/icons/ape-constructor/ape-constructor-star-stroke.svg?inline"
import LootboxOpenCardProgress from "./LootboxOpenCardProgress.vue";
export default {
  components: { IStar, LootboxOpenCardProgress },
  props: {
    items: Array,
    highlightWin: Boolean
  },
  computed: {},
};
</script>
