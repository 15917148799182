import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { useLootBoxContract } from "@/web3/contracts";
const updateApeLootBoxOpen = async (apeId) => {
  const walletCollectionStore = useWalletCollectionStore();

  const ape = walletCollectionStore.allApes.find((ape) => ape.id === apeId);
  if (!ape) return;
  let newApe = { ...ape };
  if (newApe.freeSpinsCount > 0) {
    newApe.freeSpinsCount--;
  } else if (newApe.power >= 100) {
    newApe.power = 0;
  }
  await walletCollectionStore.fetchApes();
  const newCollectionApeIdx = walletCollectionStore.allApes.findIndex(
    (ape) => ape.id === apeId
  );
  if (newCollectionApeIdx < 0) return;
  // ape with updated lootBox field
  const newCollectionApe = walletCollectionStore.allApes[newCollectionApeIdx];
  console.log("api ape", newCollectionApe);
  newApe = {
    ...newCollectionApe,
    power: newApe.power,
    freeSpinsCount: newApe.freeSpinsCount,
  };
  console.log("updated ape", newApe);

  let newApes = [...walletCollectionStore.allApes];
  newApes.splice(newCollectionApeIdx, 1, newApe);
  walletCollectionStore.allApes = newApes;
};
export const openCommonLootBox = async ({ apeId }) => {
  try {
    const ape = useWalletCollectionStore().allApes.find((ape) => ape.id === apeId);
    if (!ape) return;
    if (ape.freeSpinsCount <= 0 && useWeb3Store().meth < ape.lootBox?.price) {
      useModal().open({ name: ModalName.BuyMethModal });
      return;
    }
    const type = "common";
    const amount = 1;
    const wallet = useWeb3Store().walletAddress;
    const data = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/lootBox/open",
      params: {
        type,
        apeId,
        wallet,
        amount,
        network: Config.network.name,
      },
    });
    if (!data) throw new Error("Couldn't sign lootbox transaction");
    const { timeOut, randomSeed, signature, price, hasPower } = data;
    const contract = useLootBoxContract(true);

    const result = await useToastTransaction(
      contract.methods.openCommonBox(
        apeId,
        amount,
        price,
        0,
        timeOut,
        hasPower,
        randomSeed,
        signature
      ),
      {
        text: `Opening Common Loot Box`,
      }
    );
    let winningIds = result.events.BoxOpened.returnValues.prizes;
    winningIds = winningIds.map(Number);
    console.log(winningIds);
    const images = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/lootBox/images",
      params: {
        signature,
        txHash: result.transactionHash,
      },
    });
    const traitStars = await useApesStorageApi({
      toast: { error: true },
    }).exec({
      url: "/metadata/traits-stars",
      params: {
        type: "common",
      },
    });
    const lootBoxItems = images.map((item) => {
      const image =
        item.unisexPreviewImageSrc ||
        item.malePreviewImageSrc ||
        item.femalePreviewImageSrc;

      return {
        ...item,
        image: resizeImageCF(image, "512"),
        stars: traitStars[item.id]?.stars,
      };
    });

    useModal().close();
    useModal().open({
      name: ModalName.LootBoxOpenModal,
      props: {
        type,
        winningIds,
        items: lootBoxItems,
        apeId: apeId,
      },
    });
    updateApeLootBoxOpen(apeId);
    useWeb3Store().update();
  } catch (err) {
    captureError(err);
  }
};
