export const getApeLootBoxPrice = (ape, amount = 1) => {
  const basePrice = 600;
  const freeSpinsCount = ape.freeSpinsCount
  const hasOpenedBoxes = ape.lootBox?.lastBoxOpenedCount || 0;
  const boxesToPay = amount - freeSpinsCount;

  let finalPrice = 0;

  for (let i = hasOpenedBoxes; i < boxesToPay + hasOpenedBoxes; i++) {
    switch (i) {
      case 0:
        finalPrice += basePrice;
        break;
      case 1:
        finalPrice += (basePrice * 3) / 2;
        break;
      default:
        finalPrice += basePrice * 2;
        break;
    }
  }
  return finalPrice;
};
