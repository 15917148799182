<template>
  <div class="lootbox-open-prize" :class="winningItem.rarity">
    <div class="lootbox-open-prize__title">
      Congratulations!
      <br />
      You have uncovered {{ winningItem && winningItem.rarity === 'epic' ? 'an' : 'a' }}
      <span>{{ prizeName }}</span>!!!
    </div>
    <div
      v-if="isNew"
      class="lootbox-open-prize__new"
    >This is the first time this trait has been opened!</div>
    <div class="lootbox-open-prize-win-items">
      <div class="lootbox-open-prize-win-meth" v-if="winMethAmount">
      <img src="@/assets/img/meth.png" width="16" alt="meth" />
      <div>
        You won
        <span>{{ winMethAmount }} METH</span>
      </div>
    </div>
    <LootBoxOpenPrizeFlags :flagsEarned="flagsAmount" v-if="flagsAmount > 0" />
    </div>
    <div
      v-if="winningItem.categoryName"
      class="lootbox-open-prize__category"
    >{{ winningItem.categoryName }}</div>
    <div v-if="isNew" class="lootbox-open-prize__confetti">
      <img src="@/assets/img/confetti.gif" alt />
    </div>
   
    <div class="lootbox-open-prize__image">
      <INew v-if="isNew" width="54" class="lootbox-open-prize__image-new" />
      <img width="300" height="300" :src="winningItem.image" alt="image" />
      <!-- <div v-if="winningItem.stars >= 0 && false" class="lootbox-open-prize__stars">
        <IStar
          v-for="star in 5"
          :key="star"
          :class="{ active: star <= winningItem.stars }"
          width="14"
        />
      </div>-->
      <LootboxOpenCardProgress
        v-if="winningItem.stars >= 0"
        class="lootbox-open-prize__progress"
        :progress="winningItem.stars"
      />
      <div class="lootbox-open-prize__image-bg" />
    </div>
    <div class="lootbox-open-prize-again__info">
      <div v-if="canOpenAgain && !timerEnd" class="lootbox-open-prize-again__timer">{{ timerText }}</div>

      <div class="lootbox-open-prize__text">{{ winningItem.name }}</div>
    </div>
    

    <div v-if="canOpenAgain && !timerEnd" class="lootbox-open-prize-again">
      <button class="lootbox-open-prize-again__button" @click.prevent.stop="$emit('reopen')">
        Re-open Loot Box for {{ openAgainPrice === 0 ? 'Free' : openAgainPrice + ' Methane' }}
        <span>And increase odds of getting a rarer trait</span>
      </button>
      <LootBoxOpenPrizeOdd :current-boost="booster" :next-boost="nextBoost" />
      <!-- <div
        class="lootbox-open-prize-again__text"
      >Otherwise you have to wait until Power reaches 100%</div>-->
    </div>
    <div
      class="lootbox-open-prize__continue"
      @click.prevent.stop="$emit('next')"
    >{{ isNextClose ? 'Close Window' : 'Click to continue' }}</div>
  </div>
</template>
<script>import moment from "moment";
import { computed, onMounted, watch } from "vue-demi";
import { useTimer } from "vue-timer-hook";
import winSound from "@/assets/audio/lootbox_win.mp3";
import commonWinSound from "@/assets/audio/lootbox_win_common.mp3";
import IStar from "@/assets/icons/ape-constructor/ape-constructor-star-stroke.svg?inline"
import INew from "@/assets/icons/new.svg?inline"
import LootboxOpenCardProgress from "./LootboxOpenCardProgress.vue";
import LootBoxOpenPrizeOdd from "./LootBoxOpenPrizeOdd.vue";
import LootBoxOpenPrizeFlags from "./LootBoxOpenPrizeFlags.vue";

export default {
  components: { IStar, LootboxOpenCardProgress, LootBoxOpenPrizeFlags, LootBoxOpenPrizeOdd, INew },
  props: {
    winningItem: {
      type: Object,
      default: () => ({}),
    },
    type: String,
    canOpenAgain: Boolean,
    openAgainPrice: Number,
    canOpenTimerEnds: Date,
    isNextClose: Boolean,
    isNew: Boolean,
    flagsAmount: Number,
    booster: Number,
    maxBoost: Number,
    winMethAmount: Number
  },
  setup(props) {
    const startSound = new Audio(props.type === 'common' ? commonWinSound : winSound)
    onMounted(async () => {
      try {
        await startSound.play()
      } catch (err) {
        console.error(err)
      }
    })
    const timer = useTimer(props.canOpenTimerEnds)
    watch(() => props.canOpenTimerEnds, () => {
      timer.restart(props.canOpenTimerEnds)
    })
    const timerText = computed(() => {
      const { seconds, minutes } = timer
      return `${minutes.value < 10 ? "0" + minutes.value : minutes.value}:${seconds.value < 10 ? "0" + seconds.value : seconds.value}`;
    })
    const timerEnd = computed(() => {
      return timer.isExpired.value
    })
    const prizeName = computed(() => {
      const rarity = props.winningItem.rarity
      if (rarity === 'utility') {
        return 'Utility'
      }
      if (!rarity) return 'Trait'
      return `${rarity.charAt(0).toUpperCase() + rarity.slice(1)} Trait`
    })
    const nextBoost = computed(() => {
      return Math.min(props.booster + 1, props.maxBoost)
    })
    return {
      nextBoost,
      prizeName,
      timerEnd,
      timerText,
    }
  },
  computed: {

  },
};
</script>

<style lang="scss">
.lootbox-open-prize {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  min-width: 300px;
  &.utility & {
    &__image {
      color: var(--rarity-common);
    }
    &__title {
      span {
        color: #8c8c8c;
      }
    }
  }
  &.common & {
    &__image {
      color: var(--rarity-common);
    }
    &__title {
      span {
        color: #8c8c8c;
      }
    }
  }
  &.epic & {
    &__image {
      color: var(--rarity-epic);
    }
    &__title {
      span {
        color: var(--rarity-epic);
      }
    }
  }
  &.legendary & {
    &__image {
      color: var(--rarity-legendary);
    }

    &__title {
      span {
        color: var(--rarity-legendary);
      }
    }
  }

  &__progress {
    bottom: 15px;
  }
  &__confetti {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -2;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: none !important;
    }
  }
  &__new {
    text-align: center;
    color: var(--yellow);
    font-size: 20px;
    margin-top: -8px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  &__category {
    margin-bottom: -16px;
    position: relative;
    z-index: 10;
    background: #232323;
    padding: 6px 28px;
    text-transform: uppercase;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 24px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: bold;
  }
  &__title {
    text-align: center;
    margin-bottom: 16px;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 0 10px;
    span {
    }
  }
  &__continue {
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    max-width: 300px;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  &__text {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
  }
  &__image {
    img {
      border-radius: 10px;
      max-width: 100%;
      border: 4px solid currentColor;
      box-sizing: border-box;
    }
    width: 100%;
    max-width: 300px;

    height: auto;
    position: relative;
    &-new {
      position: absolute;
      left: 0;
      top: 0;
      color: var(--yellow);
      left: -20px;
      top: -20px;
    }
    &-bg {
      height: 90vh;
      width: 99vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        background: url(~@/assets/img/prize-bg.png) center center no-repeat;
        width: 750px;
        height: 734px;
        background-size: contain;
        animation: prizeSpin 9s linear infinite;
      }
    }
  }
  &-again {
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid rgba(255, 255, 255, 0.3);
    // background-color: var(--gray-light);
    border-radius: 5px;
    margin-top: 5px;
    &__timer {
      color: rgb(255, 82, 82);
      font-weight: bold;
      padding: 5px 15px;
      border: 1px solid rgb(182, 4, 4, 0.5);
      border-radius: 5px;
      margin-right: 5px;
      box-sizing: border-box;
      min-width: 75px;
      text-align: center;
    }
    &__info {
      display: flex;
      align-items: flex-end;
      width: 300px;
      margin-top: 5px;
      > div:only-child {
        flex: 1;
      }
    }
    &__button {
      padding: 12px 20px;
      background-color: #05873b;
      color: #fff;
      border-radius: 5px;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      box-shadow: 0 5px 0 rgba(0, 0, 0);
      transition: 0.2s;
      span {
        display: block;
        font-style: italic;
        font-size: 0.85em;
        font-weight: normal;
      }
      &:hover {
        filter: brightness(0.8);
      }
      &:active {
        filter: brightness(0.5);
      }
    }
    &__text {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      margin-top: 5px;
    }
  }
  &-flags, &-win-meth {
    display: flex;
    align-items: center;
    padding: 5px 0;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    span {
      color: var(--yellow);
    }
    svg, img {
      color: var(--yellow);

      margin-right: 5px;
    }
    &::before {
      content: '';
      width: 100%;
      border-radius: 5px;
      height: 80%;
      position: absolute;
      z-index: -1;
      left: -5px;
      top: 50%;
transform: translateY(-50%);
      background: linear-gradient(to right, rgb(255 215 114 / 35%) 0%, transparent 100%);
    }
  }
  &-win-items {
    margin-bottom: 10px;
    text-align: center;
  }
  @media screen and (max-width: 1500px) {
    &-flags, &-win-meth {
      font-size: 16px;
    }
    &-win-items {
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 768px) {
    &__confetti {
      img {
        object-fit: cover;
      }
    }
  }
  @media screen and (max-width: 576px) {
    &__title {
      font-size: 20px;
    }
    &-again {
      padding: 0;
    }
    &-again {
      &__button {
        padding: 10px 15px;

        font-size: 16px;
      }
    }
    &__new {
      font-size: 14px;
    }
  }
}
@keyframes prizeSpin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>